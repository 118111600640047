import ApiService from "@/core/services/api.service";

export const GetAssetStock = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`asset-stock/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteAssetStock = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`asset-stock/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateAssetStock = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("asset-stock", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateAssetStock = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`asset-stock/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
